<template>
    <div class="BankInformation">
        <div class="data" v-if="BankList.length">
            <div class="item" :class="`item_${item.BANK_TYPE}`" v-for="item in BankList" :key="item.ID">
                <img :src="require(`@/assets/img/pay_icon_${item.BANK_TYPE}.png`)" />
                <span class="iconfont icon-a-iconpay_huaban1fuben11" @click="DelBank(item.ID)"></span>

                <!-- 银行卡 -->
                <div class="message" v-if="item.BANK_TYPE === '1'">
                    <p class="title">
                        <span>{{ item.PAY_BANK }}</span>
                        <span class="name">{{ item.PAY_NAME }}</span>
                    </p>
                    <p class="serial">{{ item.PAY_ACCOUNT }}</p>
                    <p class="address">{{ item.PAY_ADDRESS }}</p>
                </div>

                <!-- 支付宝 -->
                <div class="message zfb" v-if="item.BANK_TYPE === '3'">
                    <p class="title">
                        <span>{{ item.PAY_BANK }}</span>
                        <span class="name">{{ item.PAY_NAME }}</span>
                    </p>
                    <p class="serial">{{ item.PAY_ACCOUNT }}</p>
                </div>

                <!-- 微信 -->
                <div class="message wx" v-if="item.BANK_TYPE === '2'">
                    <p class="title">
                        <span>{{ item.PAY_BANK }}</span>
                        <span class="name">{{ item.PAY_NAME }}</span>
                    </p>
                    <!-- <p class="serial">{{ item.PAY_ACCOUNT }}</p> -->
                </div>
            </div>
        </div>
        <NoData v-else>
            暂无收付款信息，请点击添加
        </NoData>

        <van-button class="addBtn" type="primary" block round @click="router.replace('/AddBankInformation')">增加收付款信息</van-button>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getBankList, delBank } from '@/api'
import { Toast, Dialog } from 'vant'
import NoData from '@/components/NoData.vue'

export default {
    components: {
        NoData
    },
    setup() {
        const state = reactive({
            BankList: [],
            title: '付款方式',
            value: '选择付款方式',
            show: false
        })

        const GetBankList = () => {
            getBankList().then(res => {
                console.log(111, res.data)
                state.BankList = res.data
            })
        }

        onMounted(() => {
            GetBankList()
        })

        const router = useRouter()
        const store = useStore()

        const DelBank = BankID =>
            Dialog.confirm({
                title: '温馨提示',
                message: '确认是否删除'
            })
                .then(() =>
                    delBank(BankID).then(res => {
                        if (res.status) {
                            Toast.success('删除成功！')
                            GetBankList()
                        } else {
                            Toast.fail(res.message)
                        }
                    })
                )
                .catch(() => {})

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return {
            ...toRefs(state),
            router,
            DelBank
        }
    }
}
</script>

<style lang="scss" scoped>
.BankInformation {
    position: relative;
    background: #ffffff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: h(10) w(10) h(30);

    .data {
        max-height: 80%;
        overflow: auto;
        padding: h(10) w(15) h(70);

        .item_1 {
            background: url('~@/assets/img/pay_1.png') no-repeat;
            background-size: 100% 100%;
        }
        .item_2 {
            background: url('~@/assets/img/pay_2.png') no-repeat;
            background-size: 100% 100%;
        }
        .item_3 {
            background: url('~@/assets/img/pay_3.png') no-repeat;
            background-size: 100% 100%;
        }

        .item {
            position: relative;
            height: h(130);
            border-radius: 8px;
            margin-bottom: h(12);
            color: #ffffff;

            img {
                position: absolute;
                top: h(20);
                left: w(15);
                width: h(45);
                height: h(45);
            }

            .icon-a-iconpay_huaban1fuben11 {
                position: absolute;
                top: h(25);
                right: w(18);
                font-size: 25px;
            }

            .message {
                display: inline-block;
                margin: h(25) 0 0 w(68);
                font-size: 12px;
                line-height: 16px;

                .title {
                    font-size: 14px;

                    .name {
                        margin-left: w(15);
                    }
                }

                .serial {
                    margin: h(15) 0 h(12);
                }

                .address {
                    max-width: w(240);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .zfb {
                margin: h(35) 0 0 w(68);
            }
            .wx {
                margin: h(33) 0 0 w(68);
            }
        }
    }

    .addBtn {
        @include btn();

        position: absolute;
        bottom: h(20);
        left: 0;
        right: 0;
    }
}
</style>
